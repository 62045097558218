<template>
  <div class="static">
    <v-container v-if="isPdf" class="static__container static__container--pdf tw-py-0 tw-px-4 sm:tw-px-6">
      <embed
        :src="`${staticContent}#toolbar=1&navpanes=0&scrollbar=0`"
        type="application/pdf"
        width="100%"
        height="100%"
      />
    </v-container>
    <v-container v-else class="static__container tw-p-4 sm:tw-p-6" v-html="staticContent" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'Static',
  props: {
    theme: {
      type: Object,
      required: true,
    },
  },
  async mounted() {
    if (this.staticContent === undefined) {
      this.$router.push({ name: 'Error' })
    }
  },
  computed: {
    ...mapState({
      static: state => state.global.static,
      currentLocale: state => state.global.currentLocale,
    }),
    staticContent() {
      return this.static[this.currentLocale][this.$route.params.page]
    },
    isPdf() {
      const extension = this.static[this.currentLocale][this.$route.params.page].split('.').pop()
      return extension === 'pdf'
    },
  },
  methods: {
    ...mapActions({
      getStatic: 'global/getStatic',
    }),
  },
}
</script>

<style lang="scss">
.static {
  background-color: var(--theme-static-background);
  min-height: 100%;
  color: var(--theme-static-color);

  &__container {
    a {
      color: var(--theme-static-link-color) !important;
    }

    &--pdf {
      height: calc(100vh - 56px);
    }
  }
}
</style>
